import ChatBox from "../partials/chatbox/ChatBox";
import {useContext, useEffect, useRef} from "react";
import {AppContext, components} from "../../reducer/contexts";
import {actionTypes} from "../../reducer/actionTypes";
import {speech} from "../../utilis/speech";
import {chatTimeout, pushDataLayer} from "../../utilis/common";
import patient1 from "../../assets/images/patient-1.gif";
import patient2 from "../../assets/images/patient-2.gif";
import patient3 from "../../assets/images/patient-3.gif";
import patient4 from "../../assets/images/patient-4.gif";
import doctor1 from "../../assets/images/doctor-1.png";
import doctor2 from "../../assets/images/doctor-2.png";
import doctor3 from "../../assets/images/doctor-3.png";
import doctor4 from "../../assets/images/doctor-4.png";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const Chat = () => {
    const {state, dispatch} = useContext(AppContext);
    const endChatRef = useRef();

    useEffect(() => {
        pushDataLayer("avvio_chat", state.activeChat === 1 ? "ginocchio" : state.activeChat === 2 ? "caviglia" : state.activeChat === 3 ? "cervicale" : "schiena");
    }, []);

    useEffect(() => {
        dispatch({type: actionTypes.START_STOP_PLAYING});
    }, [dispatch]);

    useEffect(() => {
        if (state.isPlaying) {
            dispatch({
                type: actionTypes.ADD_MESSAGE_TO_CHAT,
                payload: {
                    subject: "patient",
                    index: 0
                }
            });
        }
    }, [state.isPlaying, dispatch]);

    useEffect(() => {
        endChatRef.current.scrollIntoView({behavior: "smooth"});
        const timeout = setTimeout(() => endChatRef.current.scrollIntoView({behavior: "smooth"}), chatTimeout + 100);

        return () => clearTimeout(timeout);
    }, [state.chat]);

    useEffect(() => {
        let timeout;

        (async () => {
            if (!state.isPlaying) {
                endChatRef.current.scrollIntoView({behavior: "smooth"});
            }
        })();

        return () => clearTimeout(timeout);
    }, [state.isPlaying, state.chat, state.activeChat]);

    const handleFinalCommentBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.FINAL_COMMENT
    });

    return (
        <div className="flex flex-col lg:justify-center bg-orange-dark lg:bg-chat w-full h-full">
            <Header type="chat"/>

            <div className="main-container bg-white">
                <div className="relative hidden lg:block w-1/5 h-full">
                    <div className="flex flex-col justify-center items-center mt-[15%]">
                        <img
                            src={state.activeChat === 1 ? patient1 : state.activeChat === 2 ? patient2 : state.activeChat === 3 ? patient3 : patient4}
                            className="rounded-full w-7/12" draggable={false}
                            alt="patient icon"/>

                        <p className="text-xs text-blue font-bold mt-2">
                            {state.activeChat === 1 ? "MARGHERITA" : state.activeChat === 2 ? "GIULIA" : state.activeChat === 3 ? "ILARIA" : "MARIO"}*
                        </p>
                    </div>
                </div>

                <div className="flex flex-col w-full lg:w-3/5 h-full max-lg:pt-10 p-4 overflow-y-scroll">
                    {state.chat?.map((chat, index) => chat &&
                        <div className="relative h-auto" key={index}>
                            <div className={`flex max-lg:flex-col ${chat.subject === "doctor" ? "max-lg:items-end lg:justify-end" : ""} w-full`}>
                                {(speech[state.activeChat - 1][chat.subject][chat.index].type !== "question" || chat.chosen) &&
                                    <p className={`lg:hidden absolute top-0 -translate-y-full font-bold ${chat.subject === "patient" ? "text-blue" : "text-orange-dark"}`}>
                                        {chat.subject === "patient" && (state.activeChat === 1 ? "MARGHERITA" : state.activeChat === 2 ? "GIULIA" : state.activeChat === 3 ? "ILARIA" : "MARIO")}
                                        {chat.subject === "patient" && "*"}
                                        {chat.subject === "doctor" && "FARMACISTA"}
                                    </p>
                                }
                                <ChatBox subject={chat.subject} index={chat.index}/>
                            </div>

                            {(speech[state.activeChat - 1][chat.subject][chat.index].type !== "question" || chat.chosen) &&
                                <div
                                    className={`lg:hidden flex ${chat.subject === "patient" ? "justify-start" : "justify-end"} w-full mb-20`}>
                                    {chat.subject === "patient" &&
                                        <img
                                            src={state.activeChat === 1 ? patient1 : state.activeChat === 2 ? patient2 : state.activeChat === 3 ? patient3 : patient4}
                                            className="absolute top-0 right-[25%] -translate-y-1/2 w-16" alt="patient icon"/>}
                                    {chat.subject === "doctor" &&
                                        <img src={state.activeChat === 1 ? doctor1 : state.activeChat === 2 ? doctor2 : state.activeChat === 3 ? doctor3 : doctor4} className="absolute top-0 left-[25%] -translate-y-1/2 w-16" alt="doctor icon"/>}
                                </div>
                            }
                        </div>
                    )}

                    {!state.isPlaying &&
                        <div className="flex justify-center w-full">
                            <button className="text-white font-bold leading-normal bg-orange-dark px-10 py-1.5"
                                    onClick={handleFinalCommentBtnClick}>
                                Vai al<br/>
                                COMMENTO FINALE
                            </button>
                        </div>
                    }
                    <div ref={endChatRef}/>
                </div>

                <div className="relative hidden lg:block w-1/5 h-full">
                    <div className="flex flex-col justify-center items-center mt-[35%]">
                        <img
                            src={state.activeChat === 1 ? doctor1 : state.activeChat === 2 ? doctor2 : state.activeChat === 3 ? doctor3 : doctor4}
                            className="w-7/12" draggable={false}
                            alt="patient icon"/>
                        <p className="text-xs text-orange-dark font-bold mt-2">FARMACISTA</p>
                    </div>
                </div>
            </div>

            <Footer type="chat"/>
        </div>
    );
}

export default Chat;
