import {ThreeDots} from "react-loader-spinner";
import styles from "../../../styles/ChatBox.module.scss";
import {AppContext} from "../../../reducer/contexts";
import {useContext} from "react";

const ChatBoxPatient = ({isLoaded, currentSpeech, type}) => {
    const {state} = useContext(AppContext);

    return (
        <div className={`${styles.chatBox} ${state.activeChat === 1 ? "bg-gray" : state.activeChat === 2 ? "bg-orange-light" : state.activeChat === 3 ? "bg-blue-light" : "bg-gray-light"} lg:mb-14 ${type === "comment" ? "lg:!w-[92%] lg:!pl-[8%] !mb-0" : "self-start"} max-lg:!pt-8`}>

            <div className={`${styles.chatBoxArrow} left-[10%] !border-l-0 ${state.activeChat === 1 ? "!border-t-gray" : state.activeChat === 2 ? "!border-t-orange-light" : state.activeChat === 3 ? "!border-t-blue-light" : "!border-t-gray-light"}`}/>

            {!isLoaded &&
                <div className="flex items-center h-[1.24rem]">
                    <ThreeDots height="0.75rem" radius="9" color="#25305D"
                               ariaLabel="three-dots-loading"
                               wrapperStyle={{}} wrapperClassName="" visible={true}/>
                </div>
            }

            {isLoaded &&
                <p className={`${type === "comment" ? "text-sm lg:text-[0.75rem]" : "text-blue lg:text-sm font-bold"} w-full`}>
                    {currentSpeech.text}
                </p>
            }
        </div>
    );
}

export default ChatBoxPatient;
