import arrow from "../../assets/images/arrow.png";

const RoundedButton = ({text, color, className, onClick}) => {
    return (
        <button
            className={`${className} flex justify-center items-center text-white lg:text-xs font-bold ${color === "orange" ? "bg-orange-dark" : "bg-blue"} rounded-br-full max-lg:w-4/5 h-14 lg:h-[10%] px-6`}
            onClick={onClick}>
            {text}
            <img src={arrow} className="w-auto h-1/3 ml-2" alt="Freccia"/>
        </button>
    );
}

export default RoundedButton;
