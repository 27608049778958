import Header from "../partials/Header";
import {AppContext, components} from "../../reducer/contexts";
import {useContext, useEffect} from "react";
import {speech} from "../../utilis/speech";
import finalComment from "../../assets/images/final-comment.png";
import finalCommentMobile from "../../assets/images/final-comment-mobile.png";
import Footer from "../partials/Footer";
import {actionTypes} from "../../reducer/actionTypes";
import RoundedButton from "../partials/RoundedButton";
import {pushDataLayer} from "../../utilis/common";

const FinalComment = () => {
    const {state, dispatch} = useContext(AppContext);

    useEffect(() => {
        pushDataLayer("commento_finale", state.activeChat === 1 ? "ginocchio" : state.activeChat === 2 ? "caviglia" : state.activeChat === 3 ? "cervicale" : "schiena");
    }, []);

    const handleDiscoverProductBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.PRODUCT,
    });

    return (
        <div
            className="relative flex flex-col lg:justify-center bg-blue lg:bg-final-comment lg:bg-cover w-full h-full">
            <Header type="comment"/>

            <div
                className={`main-container max-lg:flex-col justify-between ${state.activeChat === 1 ? "bg-gray" : state.activeChat === 2 ? "bg-orange-light" : state.activeChat === 3 ? "bg-blue-light" : "bg-gray-light"} max-lg:!h-[78%] max-lg:overflow-auto py-4`}>
                <div className="text-blue w-full lg:w-2/3 lg:h-full lg:overflow-auto px-4">
                    <p className="text-xl lg:text-lg font-semibold">
                        CONSIGLIO FINALE PER IL FARMACISTA<sup>1</sup>
                    </p>
                    <p className="lg:text-[.8rem]">
                        {speech[state.activeChat - 1].doctor[speech[state.activeChat - 1].doctor.length - 1].text}
                    </p>
                </div>

                <div className="relative flex justify-end w-full lg:w-1/3 lg:h-full">
                    <img src={finalComment} className="hidden lg:block w-auto h-5/6 mt-[6%]" alt="Farmacista paziente"/>
                    <img src={finalCommentMobile} className="lg:hidden w-2/3 h-auto mt-14 lg:mt-[6%] max-lg:mb-10"
                         alt="Farmacista paziente"/>

                    <RoundedButton text="SCOPRI IL PRODOTTO" color="blue"
                                   className="absolute max-lg:left-4 max-lg:top-10 lg:bottom-[15%] lg:right-[15%]"
                                   onClick={handleDiscoverProductBtnClick}/>
                </div>
            </div>

            <Footer type="comment"/>
        </div>
    );
}

export default FinalComment;
