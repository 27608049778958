import legalNotes from "../../assets/pdf/legal-notes.pdf";
import privacyPolicy from "../../assets/pdf/privacy-policy.pdf";

const Footer = ({type, className}) => {
    return (
        <div
            className={`${type === "comment" || type === "product" ? "flex" : "flex"} flex-grow ${type === "chat" ? "max-lg:!justify-center" : ""} justify-between items-center ${type === "product" ? "text-blue" : "text-white"} text-[.7rem] lg:text-[.5rem] w-full px-4 ${className}`}>
            <div className={`flex max-lg:flex-col max-lg:1/4 lg:space-x-5 lg:text-white`}>
                <a href={privacyPolicy} className="cursor-pointer" target="_blank" rel="noreferrer">
                    Privacy e cookie policy
                </a>
                <a href={legalNotes} className="cursor-pointer" target="_blank" rel="noreferrer">
                    Note legali
                </a>
            </div>

            {type !== "references" &&
                <p className="max-lg:text-end max-lg:w-2/3">
                    *Profilo ipotetico, non rappresenta un/a paziente reale.
                </p>
            }
        </div>
    );
}

export default Footer;
