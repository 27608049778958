import Header from "../partials/Header";
import Footer from "../partials/Footer";
import {AppContext} from "../../reducer/contexts";
import {useContext, useEffect} from "react";
import logo1 from "../../assets/images/logos/logo-1.png";
import logo2 from "../../assets/images/logos/logo-2.png";
import logo3 from "../../assets/images/logos/logo-3.png";
import logo4 from "../../assets/images/logos/logo-4.png";
import product1 from "../../assets/images/products/product-1.png";
import product2 from "../../assets/images/products/product-2.png";
import product3 from "../../assets/images/products/product-3.png";
import product4 from "../../assets/images/products/product-4.png";
import RoundedButton from "../partials/RoundedButton";
import voltaren2 from "../../assets/pdf/RCP-Voltaren-2.pdf";
import voltalgan from "../../assets/pdf/RCP-Voltalgan.pdf";
import voltadvanceGo from "../../assets/pdf/RCP-Voltadvance-Go.pdf";
import voltadolUnidie from "../../assets/pdf/RCP-Voltadol-Unidie.pdf";
import {pushDataLayer} from "../../utilis/common";

const Product = () => {
    const {state} = useContext(AppContext);

    useEffect(() => {
        pushDataLayer("scopri_prodotto", state.activeChat === 1 ? "ginocchio" : state.activeChat === 2 ? "caviglia" : state.activeChat === 3 ? "cervicale" : "schiena");
    }, []);

    const handleRCPBtnClick = () => {
        switch (state.activeChat) {
            case 1:
                window.open(voltaren2, "_blank");
                break;
            case 2:
                window.open(voltalgan, "_blank");
                break;
            case 3:
                window.open(voltadvanceGo, "_blank");
                break;
            case 4:
                window.open(voltadolUnidie, "_blank");
        }
    }

    return (
        <div
            className={`relative flex flex-col lg:justify-center ${state.activeChat === 1 ? "bg-gray lg:bg-product-1" : state.activeChat === 2 ? "bg-orange-light lg:bg-product-2" : state.activeChat === 3 ? "bg-blue-light lg:bg-product-3" : "bg-gray-light lg:bg-product-4"} lg:bg-cover w-full h-full`}>
            <Header type="comment"/>

            <div className="main-container text-sm bg-white w-full max-lg:overflow-auto p-4">
                <div className="text-blue w-full lg:w-1/2 h-full overflow-auto">
                    <div className="flex max-lg:flex-col max-lg:items-center w-full">
                        <img
                            src={state.activeChat === 1 ? logo1 : state.activeChat === 2 ? logo2 : state.activeChat === 3 ? logo3 : logo4}
                            className={`w-full ${state.activeChat === 4 ? "!w-4/5 lg:!w-1/2" : "lg:w-2/3"} max-lg:max-w-product h-auto object-contain`}
                            alt="Logo"/>

                        <img
                            src={state.activeChat === 1 ? product1 : state.activeChat === 2 ? product2 : state.activeChat === 3 ? product3 : product4}
                            className={`lg:hidden ${state.activeChat < 3 ? "w-full" : state.activeChat === 3 ? "w-4/5" : "w-2/3"} max-lg:max-w-product h-auto mt-5`}
                            alt="Prodotto"/>
                    </div>

                    <div className="mt-5">
                        {state.activeChat === 1 &&
                            <>
                                <p className="font-bold">
                                    Classificazione ai fini della fornitura:
                                </p>

                                <p>
                                    medicinale di automedicazione.
                                </p>

                                <p className="font-bold">
                                    Regime di dispensazione:
                                </p>

                                <p>
                                    Classe C-bis.
                                </p>

                                <p className="font-bold">
                                    Prezzo indicativo per la confezione da 60 g, per la confezione da 100 g e per la
                                    confezione da
                                    180 g:
                                </p>

                                <p>
                                    ai sensi della Legge 296/2006 il prezzo è stabilito da ciascun titolare di punto
                                    vendita.
                                </p>

                                <p className="font-bold">
                                    Concessionario per la vendita:
                                </p>

                                <p>
                                    Haleon Italy S.r.l.
                                </p>

                                <p className="font-bold">
                                    Titolare AIC:
                                </p>

                                <p>
                                    Novartis Farma S.p.A.
                                </p>
                            </>
                        }

                        {state.activeChat === 2 &&
                            <>
                                <p className="font-bold">
                                    Classificazione ai fini della fornitura:
                                </p>

                                <p>
                                    medicinale di automedicazione.
                                </p>

                                <p className="font-bold">
                                    Regime di dispensazione:
                                </p>

                                <p>
                                    Classe C-bis.
                                </p>

                                <p className="font-bold">
                                    Prezzo indicativo:
                                </p>

                                <p>
                                    ai sensi della Legge 296/2006 il prezzo è stabilito da ciascun titolare di punto
                                    vendita.
                                </p>

                                <p className="font-bold">
                                    Titolare AIC:
                                </p>

                                <p>
                                    Haleon Italy S.r.l.
                                </p>
                            </>
                        }

                        {state.activeChat === 3 &&
                            <>
                                <p className="font-bold">
                                    Classificazione ai fini della fornitura:
                                </p>

                                <p>
                                    medicinale di automedicazione.
                                </p>

                                <p className="font-bold">
                                    Regime di dispensazione:
                                </p>

                                <p>
                                    Classe C-bis.
                                </p>

                                <p className="font-bold">
                                    Prezzo indicativo per la confezione da 20 capsule:
                                </p>

                                <p>
                                    ai sensi della Legge 296/2006 il prezzo è stabilito da ciascun titolare di punto
                                    vendita.

                                </p>

                                <p className="font-bold">
                                    Titolare AIC:
                                </p>

                                <p>
                                    Haleon Italy S.r.l.
                                </p>
                            </>
                        }

                        {state.activeChat === 4 &&
                            <>
                                <p className="font-bold">
                                    Classificazione ai fini della fornitura:
                                </p>

                                <p>
                                    medicinale di automedicazione.
                                </p>

                                <p className="font-bold">
                                    Regime di dispensazione:
                                </p>

                                <p>
                                    Classe C-bis.
                                </p>

                                <p className="font-bold">
                                    Prezzo indicativo per la confezione da 5 cerotti e per la confezione da 10 cerotti:
                                </p>

                                <p>
                                    ai sensi della Legge 296/2006 il prezzo è stabilito da ciascun titolare di punto
                                    vendita.
                                </p>

                                <p className="font-bold">
                                    Titolare AIC:
                                </p>

                                <p>
                                    Haleon Italy S.r.l.
                                </p>
                            </>
                        }

                        <RoundedButton text="CONSULTA IL RCP" color="orange" className="lg:hidden !w-full mt-5"
                                       onClick={handleRCPBtnClick}/>
                    </div>
                </div>

                <div className="hidden lg:flex flex-col justify-evenly items-center lg:w-1/2 h-full">
                    <img
                        src={state.activeChat === 1 ? product1 : state.activeChat === 2 ? product2 : state.activeChat === 3 ? product3 : product4}
                        className={`${state.activeChat < 3 ? "w-5/6" : state.activeChat === 3 ? "w-3/5" : "w-1/2"} h-auto mt-2`}
                        alt="Prodotto"/>

                    <RoundedButton text="CONSULTA IL RCP" color="orange" onClick={handleRCPBtnClick}/>
                </div>
            </div>

            <Footer type="product"/>
        </div>
    );
}

export default Product;
