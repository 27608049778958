export const speech = [
    {
        patient: [
            {
                id: 0,
                text:
                    <>
                        Buongiorno, ho un dolore al ginocchio molto fastidioso. Potrebbe suggerirmi un antidolorifico?
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 0,
                }
            },
            {
                id: 1,
                text:
                    <>
                        Certamente.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 1,
                }
            },
            {
                id: 2,
                text:
                    <>
                        Il dolore si è intensificato da qualche giorno e mi limita durante la giornata.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                }
            },
            {
                id: 3,
                text:
                    <>
                        No, non sono caduta ma il dolore ultimamente è peggiorato.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                },
            },
            {
                id: 4,
                text:
                    <>
                        Faccio fatica a muoverlo, ho difficoltà anche ad alzarmi dalla sedia e sto meglio se sto a
                        riposo.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Direi 5-6, specialmente quando inizio a muoverlo.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 6,
                text:
                    <>
                        No, provo solo dolore.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Ho notato solo una modesta rigidità al risveglio ma si risolve rapidamente.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Soffro di diabete.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        È un dolore diffuso, profondo, non saprei indicare un punto preciso.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 10,
                text:
                    <>
                        No, di solito no.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        No, è la prima volta.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 11,
                },
            },
            {
                id: 12,
                text:
                    <>
                        Sì, ho preso un appuntamento, ma nel frattempo volevo qualcosa per attenuare il dolore.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 12,
                },
            },
            {
                id: 13,
                text:
                    <>
                        Grazie, tornerò sicuramente.
                    </>,
                type: "text",
            },
        ],
        doctor: [
            {
                id: 0,
                text:
                    <>
                        Posso farle prima qualche domanda per capire meglio cosa consigliarle?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 1,
                },
            },
            {
                id: 1,
                text:
                    <>
                        Da quanto tempo lamenta questo dolore?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 2,
                },
            },
            {
                id: 2,
                text:
                    <>
                        È caduta recentemente?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 3,
                },
            },
            {
                id: 3,
                text:
                    <>
                        Riesce a muovere il ginocchio?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 4,
                },
            },
            {
                id: 4,
                text:
                    <>
                        Potrebbe quantificare l’entità del dolore in una scala da 1 a 10?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Sono presenti gonfiori, arrossamenti o calore al tatto?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 6,
                },
            },
            {
                id: 6,
                text:
                    <>
                        Al mattino appena sveglia ha rigidità per più di 30 minuti?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Ha altre malattie per le quali assume farmaci?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 8,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Saprebbe dirmi con precisione dov’è localizzato il dolore?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Ci sono farmaci che le danno problemi di stomaco o intestinali?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 10,
                },
            },
            {
                id: 10,
                text:
                    <>
                        Ha già sofferto in passato di dolori simili a questo?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        Ha già contattato il suo medico?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 12,
                },
            },
            {
                id: 12,
                text:
                    <>
                        Inizio a consigliarle un <span className="text-blue">antidolorifico topico a base di diclofenac dietilammonio al
                        2%<sup>2</sup>, da applicare 2 volte al giorno</span>, dopo la visita passi di qui per farmi
                        sapere come va e cosa le ha consigliato.
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 13,
                },
            },
            {
                id: 13,
                text:
                    <>
                        Alcuni degli elementi descritti dalla persona indirizzano verso la patologia artrosica come
                        causa del dolore: età avanzata, limitazione nei movimenti attivi, dolore percepito come profondo
                        e poco localizzabile, è diabetica e sovrappeso, due fattori di rischio per artrosi. Inoltre, la
                        rigidità mattutina e il dolore che tende a ridursi con il movimento sono elementi tipici delle
                        fasi non avanzate dell’artrosi.<br/><br/>
                        <span className="font-bold">Come prima linea di trattamento così come indicato anche dalle Linee guida internazionali,
                        si suggerisce l’utilizzo di FANS topici (specie in pazienti che assumono molti farmaci per os o con
                        dolore modesto)</span>. Il ricorso al FANS per via orale rappresenta la seconda linea di
                        trattamento che comunque deve essere sempre consigliato alla minima dose terapeutica per il più
                        breve tempo possibile (pz. segnala tolleranza a FANS).<br/><br/>
                        Si consiglia di non sollevare pesi e ridurre gli sforzi fisici nell’attività quotidiana specie
                        se comportano dolore sino a controllo medico.<br/><br/>
                        In generale, si suggerisce al paziente di privilegiare un’attività fisica dolce e di perdere
                        peso, entrambe sotto controllo medico.
                    </>,
                type: "text",
            },
        ],
    },
    {
        patient: [
            {
                id: 0,
                text:
                    <>
                        Buongiorno, mi sono fatta male alla caviglia, cosa mi consiglia di fare?
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 0,
                }
            },
            {
                id: 1,
                text:
                    <>
                        Certamente.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 1,
                }
            },
            {
                id: 2,
                text:
                    <>
                        Questa mattina, mentre correvo ma purtroppo il dolore non passa.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                }
            },
            {
                id: 3,
                text:
                    <>
                        Nella zona laterale della caviglia.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                },
            },
            {
                id: 4,
                text:
                    <>
                        No, ho solo appoggiato male il piede, ma ho continuato a correre. Il dolore è comparso dopo.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Sì, si è gonfiata un po’ ma non è viola.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 6,
                text:
                    <>
                        Direi 4-5, in particolare se sto molto tempo in piedi.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Sì, riesco a camminare ma se cammino molto mi fa male.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Sì, mi era già capitata una distorsione alla stessa caviglia.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Sì, non avevo più alcun fastidio.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 10,
                text:
                    <>
                        Ho messo il ghiaccio, ma vorrei un antidolorifico.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        No, non ho assunto alcun farmaco.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 11,
                },
            },
            {
                id: 12,
                text:
                    <>
                        Grazie mille, le farò sapere.
                    </>,
                type: "text",
            },
        ],
        doctor: [
            {
                id: 0,
                text:
                    <>
                        Posso farle qualche domanda per capire meglio la situazione?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 1,
                },
            },
            {
                id: 1,
                text:
                    <>
                        Quando è avvenuto il trauma?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 2,
                },
            },
            {
                id: 2,
                text:
                    <>
                        Potrebbe mostrarmi dove è localizzato il dolore?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 3,
                },
            },
            {
                id: 3,
                text:
                    <>
                        Ha percepito del rumore durante l’evento traumatico?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 4,
                },
            },
            {
                id: 4,
                text:
                    <>
                        La caviglia si è gonfiata? Sono comparsi ematomi?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Da 1 a 10 quanto è intenso il dolore?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 6,
                },
            },
            {
                id: 6,
                text:
                    <>
                        Riesce a camminare?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Ha già avuto eventi traumatici simili?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 8,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Aveva poi recuperato completamente?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Ha già fatto qualcosa per il dolore?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 10,
                },
            },
            {
                id: 10,
                text:
                    <>
                        Ha preso già qualche farmaco?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        Inizio a consigliarle un antinfiammatorio topico, ad esempio, <span className="text-blue">in schiuma cutanea a base di
                        diclofenac 3%<sup>3</sup> da applicare da 1 a 3 volte al giorno</span>; mi raccomando contatti
                        il suo medico curante e tra qualche giorno ripassi da me per aggiornarmi sul dolore.
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 12,
                },
            },
            {
                id: 12,
                text:
                    <>
                        Potrebbe trattarsi di un dolore da trauma distorsivo minore dovuto all’evento avvenuto durante
                        la sessione di corsa.<br/><br/>
                        <span className="font-bold">Si suggerisce l’utilizzo di FANS topico o, se non sufficiente, di FANS per via orale soprattutto
                        in caso di dolore profondo, che comunque deve essere sempre consigliato alla minima dose
                            terapeutica per il più breve tempo possibile.</span><br/><br/>
                        Sono utili il riposo articolare e la sospensione dell’attività fisica intensa per almeno una
                        settimana; continuare applicazione ciclica di ghiaccio almeno nelle prime 24-36 ore, per un
                        periodo di tempo non superiore ai 15 minuti. Accompagnare la compressione dell’articolazione e
                        mantenerla in elevazione secondo il Protocollo P.R.I.C.E. (Protection, Rest, Ice, Compression,
                        Elevation).<br/><br/>
                        Per evitare recidive, è importante non sottovalutare il problema e, se il dolore persiste,
                        rivolgersi al proprio medico curante.
                    </>,
                type: "text",
            },
        ],
    },
    {
        patient: [
            {
                id: 0,
                text:
                    <>
                        Buongiorno, potrebbe darmi qualcosa per il dolore alla zona cervicale?
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 0,
                }
            },
            {
                id: 1,
                text:
                    <>
                        Certamente.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 1,
                }
            },
            {
                id: 2,
                text:
                    <>
                        A livello della zona cervicale.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                }
            },
            {
                id: 3,
                text:
                    <>
                        Da un paio di giorni, ho provato a resistere e a vedere se poteva passare da solo.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                },
            },
            {
                id: 4,
                text:
                    <>
                        No, nessun trauma, lavoro come infermiera e nell’ultimo mese ho lavorato ore extra per coprire
                        alcuni colleghi in ferie.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        No, non ho notato rigidità al risveglio neppure durante la giornata.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 6,
                text:
                    <>
                        No, sento solo dolore a livello cervicale.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        No, nessun tremore.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 8,
                text:
                    <>
                        No, di solito no. Sì, preferirei una pastiglia piccola e facilmente deglutibile.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Sì, in effetti l’anno scorso ho avuto qualcosa di simile.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 10,
                text:
                    <>
                        No, ma vorrei andarci.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 10,
                },
            },
            {
                id: 11,
                text:
                    <>
                        Grazie, tornerò sicuramente.
                    </>,
                type: "text",
            },
        ],
        doctor: [
            {
                id: 0,
                text:
                    <>
                        Posso farle prima qualche domanda per capire meglio cosa consigliarle?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 1,
                },
            },
            {
                id: 1,
                text:
                    <>
                        Potrebbe indicarmi dov’è localizzato esattamente il dolore?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 2,
                },
            },
            {
                id: 2,
                text:
                    <>
                        Da quanto le fa male?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 3,
                },
            },
            {
                id: 3,
                text:
                    <>
                        Ha iniziato a farle male in seguito ad un trauma oppure ha mantenuto posture scorrette?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 4,
                },
            },
            {
                id: 4,
                text:
                    <>
                        Al mattino appena sveglia ha notato anche una certa rigidità a livello cervicale che magari
                        migliora durante la giornata?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Ha notato alterazioni posturali al collo/mal di testa forti o comparsi recentemente?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 6,
                },
            },
            {
                id: 6,
                text:
                    <>
                        Sente per caso anche tremori alla testa?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Ci sono farmaci che le danno problemi di stomaco o intestinali? Ha delle difficoltà di
                        deglutizione?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 8,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Ha già sofferto in passato di dolori simili a questo?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Ha già contattato il suo medico?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 10,
                },
            },
            {
                id: 10,
                text:
                    <>
                        Inizio a consigliarle un <span className="text-blue">antinfiammatorio orale a base di diclofenac epolamina 25 mg<sup>6</sup>,
                        fino ad una dose massima giornaliera di 75 mg</span>, dopo la visita con il suo medico passi di
                        qui per farmi sapere come va e cosa le ha consigliato.
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        Potrebbe trattarsi di cervicalgia che colpisce maggiormente le donne tra i 40 e i 50 anni e
                        alcune categorie di lavoratori, ad esempio chi fa un lavoro d’ufficio come gli impiegati, chi
                        lavora nell’ambito sanitario come gli infermieri e chi fa lavori manuali come i
                        muratori<sup>7</sup>. Tra i principali responsabili della cervicalgia ci sono ad esempio:
                        postura scorretta<sup>8</sup>, vita sedentaria, traumi, disturbi del sonno<sup>5</sup>, alcune
                        lesioni
                        sportive<sup>7</sup>.<br/><br/>
                        L’assenza di rigidità mattutina, tremori alla testa, alterazioni posturali
                        tendono ad escludere problematiche reumatologiche o neurologiche mentre il dolore localizzato e
                        l’assenza di traumi fa pensare a problematiche di tipo muscolare<sup>4</sup>.<br/><br/>
                        <span className="font-bold">Per la cervicalgia si consiglia la terapia con FANS orali alla più bassa dose specifica per il minor tempo
                            possibile<sup>1,9</sup>.</span><br/><br/>
                        Se il dolore persiste e peggiora (limitazione nei movimenti cervicali<sup>5</sup>, debolezza e
                        rigidità muscolare a livello del collo<sup>4-5</sup>, dolore che si irradia alle braccia o alla
                        zona scapolare<sup>4</sup>), si consiglia di rivolgersi al proprio medico curante.
                    </>,
                type: "text",
            }
        ],
    },
    {
        patient: [
            {
                id: 0,
                text:
                    <>
                        Buongiorno, avrei bisogno di qualcosa per il mal di schiena
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 0,
                }
            },
            {
                id: 1,
                text:
                    <>
                        Certamente.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 1,
                }
            },
            {
                id: 2,
                text:
                    <>
                        Mi fa male nella parte bassa della schiena, a sinistra.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                }
            },
            {
                id: 3,
                text:
                    <>
                        Non saprei con precisione, credo 5. Fa male, ma posso muovermi.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 3,
                },
            },
            {
                id: 4,
                text:
                    <>
                        Stavo facendo il trasloco, ho sollevato una scatola piena di libri e dopo lo sforzo ho dovuto
                        smettere per il dolore.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Sì, faccio un lavoro in cui sono sempre seduto ma quando è comparso il dolore stavo sollevando
                        una scatola piena di libri.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 5,
                },
            },
            {
                id: 6,
                text:
                    <>
                        Da un paio di giorni; il dolore non è forte e non mi impedisce di muovermi ma non mi è ancora
                        passato.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Il dolore non si è modificato, lo percepisco solo nella parte bassa della schiena ma è
                        fastidioso e lo avverto maggiormente con alcuni movimenti.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 7,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Sì, solitamente gioco a calcetto una volta a settimana e nei weekend vado a fare trekking.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Sì, mi è già capitato. Di solito capita sempre dopo lo sport.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 9,
                },
            },
            {
                id: 10,
                text:
                    <>
                        No, di solito no.
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        No, non mi è mai capitato di prendere farmaci per il mal di schiena e non sto assumendo farmaci
                        per altre malattie
                    </>,
                type: "answer",
                next: {
                    subject: "doctor",
                    index: 11,
                },
            },
            {
                id: 12,
                text:
                    <>
                        No. In realtà volevo solamente qualcosa per far passare il dolore.
                    </>,
                type: "text",
                next: {
                    subject: "doctor",
                    index: 12,
                },
            },
            {
                id: 13,
                text:
                    <>
                        Grazie, sicuramente sentirò anche il medico.
                    </>,
                type: "text",
            },
        ],
        doctor: [
            {
                id: 0,
                text:
                    <>
                        Posso farle qualche domanda per capire meglio cosa consigliarle?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 1,
                },
            },
            {
                id: 1,
                text:
                    <>
                        Mi può indicare con precisione dove le fa male?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 2,
                },
            },
            {
                id: 2,
                text:
                    <>
                        Potrebbe quantificare l’entità del dolore in una scala da 1 a 10?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 3,
                },
            },
            {
                id: 3,
                text:
                    <>
                        Cosa stava facendo quando è comparso il dolore?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 4,
                },
            },
            {
                id: 4,
                text:
                    <>
                        Trascorre molto tempo seduto o ha assunto una posizione scorretta?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 5,
                },
            },
            {
                id: 5,
                text:
                    <>
                        Da quanto tempo le fa male?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 6,
                },
            },
            {
                id: 6,
                text:
                    <>
                        Il dolore è sempre stato localizzato in un punto preciso oppure si è irradiato anche ad altre
                        parti del corpo? Ad esempio, ai fianchi o ai glutei?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 7,
                },
            },
            {
                id: 7,
                text:
                    <>
                        Pratica sport o attività fisica abitualmente?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 8,
                },
            },
            {
                id: 8,
                text:
                    <>
                        Ha già sofferto in passato di mal di schiena?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 9,
                },
            },
            {
                id: 9,
                text:
                    <>
                        Ci sono farmaci che le danno problemi di stomaco o intestinali?
                    </>,
                type: "question",
                questionEnd: false,
                next: {
                    subject: "patient",
                    index: 10,
                },
            },
            {
                id: 10,
                text:
                    <>
                        In passato, è stato necessario assumere medicinali per il mal di schiena oppure ne prende altri
                        per altre malattie?
                    </>,
                type: "question",
                questionEnd: true,
                next: {
                    subject: "patient",
                    index: 11,
                },
            },
            {
                id: 11,
                text:
                    <>
                        Ha già contattato il suo medico?
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 12,
                },
            },
            {
                id: 12,
                text:
                    <>
                        Inizio a consigliarle un <span className="text-blue">antidolorifico topico sotto forma di cerotto medicato a base di 140 mg
                        di diclofenac sodico<sup>10</sup>, applicato una sola volta al giorno</span>; nei prossimi
                        giorni, se avesse modo di passare in farmacia, mi farebbe piacere sapere l’evoluzione dei suoi
                        disturbi. Nel frattempo, contatti comunque il suo medico curante anche per capire come prevenire
                        episodi futuri.
                    </>,
                type: "text",
                next: {
                    subject: "patient",
                    index: 13,
                },
            },
            {
                id: 13,
                text:
                    <>
                        <span className="font-bold">Potrebbe trattarsi di lombalgia acuta, per la quale si suggerisce l’utilizzo di FANS a uso
                        topico (cerotto medicato, gel o schiuma), riduzione delle sollecitazioni fisiche per alcuni
                            giorni e/o ripresa dell’attività sportiva con gradualità.</span> La sintomatologia dolorosa
                        acuta è verosimilmente legata allo sforzo/movimento errato. Infatti, il soggetto segnala di
                        praticare attività sportiva, lamenta saltuariamente fastidi alla schiena al termine
                        dell’attività, ma allo stesso tempo di non aver mai assunto medicinali in passato per questo
                        problema. Il razionale della scelta topica deriva dalla documentata efficacia di questa via e
                        dalla necessità di un’azione graduale e progressiva solamente ove necessario.<br/><br/>
                        In caso di dolore profondo e limitazione nei movimenti, è consigliato assumere un FANS per via
                        orale che comunque deve essere sempre consigliato alla minima dose terapeutica per il più breve
                        tempo possibile.<br/><br/>
                        Se il dolore persiste e peggiora (limitazione consistente delle attività quotidiane, dolore a
                        riposo e irradiazione del dolore agli arti inferiori), si consiglia di rivolgersi al proprio
                        medico curante.

                    </>,
                type: "text",
            },
        ],
    },
];
