import {AppContext, appReducerInitialState, components} from "./reducer/contexts";
import AppReducer from "./reducer/appReducer";
import {useEffect, useReducer} from "react";
import Home from "./components/pages/Home";
import Chat from "./components/pages/Chat";
import FinalComment from "./components/pages/FinalComment";
import rotateIcon from "./assets/images/icons/rotate-icon.png";
import {isMobile} from "react-device-detect";
import Product from "./components/pages/Product";
import References from "./components/pages/References";
import styles from "./styles/App.module.scss";

const App = () => {
    const [state, dispatch] = useReducer(AppReducer, appReducerInitialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            <div id={styles.appContainer} className="relative bg-white lg:shadow-2xl lg:shadow-[rgba(0,0,0,0.4)] max-w-[1920px] lg:aspect-video lg:overflow-hidden h-full lg:h-auto lg:my-auto lg:mx-auto">
                <div className={`${isMobile ? "landscape:max-[1023px]:hidden" : ""} w-full h-full`}>
                    {state.activeComponent === components.HOME && <Home/>}
                    {state.activeComponent === components.CHAT && <Chat/>}
                    {state.activeComponent === components.FINAL_COMMENT && <FinalComment/>}
                    {state.activeComponent === components.PRODUCT && <Product/>}
                    {state.activeComponent === components.REFERENCES && <References/>}
                </div>

                {isMobile &&
                    <div className="hidden landscape:max-lg:flex flex-col justify-center items-center w-full h-full">
                        <img src={rotateIcon} className="h-1/4" alt="rotate device"/>
                        <p className="text-white text-2xl font-bold mt-4">
                            RUOTA IL DEVICE
                        </p>
                    </div>
                }
            </div>
        </AppContext.Provider>
    );
}

export default App;
