import {ThreeDots} from "react-loader-spinner";
import {useContext, useState} from "react";
import {actionTypes} from "../../../reducer/actionTypes";
import {AppContext} from "../../../reducer/contexts";
import styles from "../../../styles/ChatBox.module.scss";

const ChatBoxDoctor = ({isLoaded, currentSpeech, index}) => {
    const {dispatch} = useContext(AppContext);
    const [isQuestion, setIsQuestion] = useState(currentSpeech.type === "question");

    const handleChatBoxBtnClick = () => {
        if (isQuestion && isLoaded) {
            setIsQuestion(false)

            dispatch({
                type: actionTypes.REMOVE_QUESTION,
                payload: index
            });

            dispatch({
                type: actionTypes.ADD_MESSAGE_TO_CHAT,
                payload: {
                    subject: currentSpeech.next.subject,
                    index: currentSpeech.next.index
                }
            });
        }
    }

    return (
        <div
            className={`${styles.chatBox} ${isQuestion ? styles.chatBoxQuestion : "max-lg:!pt-8 lg:mb-14"} justify-end text-white bg-orange-dark`}
            onClick={handleChatBoxBtnClick}>

            {!isQuestion && <div className={`${styles.chatBoxArrow} right-[10%] !border-r-0 !border-t-orange-dark`}/>}

            {!isLoaded &&
                <div className="flex items-center h-[1.24rem]">
                    <ThreeDots height="0.75rem" radius="9" color={isQuestion ? "#25305D" : "white"}
                               ariaLabel="three-dots-loading"
                               wrapperStyle={{}} wrapperClassName="" visible={true}/>
                </div>
            }

            {isLoaded &&
                <p className={`lg:text-sm text-end ${isQuestion ? "me-4" : "font-bold"} w-full`}>
                    {currentSpeech.text}
                </p>
            }
        </div>
    );
}

export default ChatBoxDoctor;
