import {AppContext, components} from "../../reducer/contexts";
import {useContext} from "react";
import homeIcon from "../../assets/images/icons/home-icon.png";
import bookIcon from "../../assets/images/icons/book-icon.png";
import {actionTypes} from "../../reducer/actionTypes";

const Header = ({type}) => {
    const {state, dispatch} = useContext(AppContext);

    const handleHomeBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.HOME,
    });

    const handleReferencesBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.REFERENCES,
    });

    return (
        <div className="flex justify-between w-full max-lg:bg-chat-mobile h-1/6 lg:h-1/5 px-4">
            <div
                className="flex max-lg:flex-col max-lg:justify-evenly lg:items-center lg:w-1/6 h-full lg:space-x-3">
                <button
                    className="flex justify-center items-center bg-white rounded-full h-[30%] lg:h-2/5 aspect-square"
                    onClick={handleHomeBtnClick}>
                    <img src={homeIcon} className="w-auto h-3/5" alt="Home"/>
                </button>

                {type !== "references" &&
                    <button
                        className="flex justify-center items-center bg-white rounded-full h-[30%] lg:h-2/5 aspect-square"
                        onClick={handleReferencesBtnClick}>
                        <img src={bookIcon} className="w-auto h-3/5" alt=""/>
                    </button>}
            </div>

            <div className="flex justify-end w-[90%] lg:w-5/6 h-full pl-4">
                {type !== "references" &&

                    <div
                        className="flex flex-col justify-center text-white text-end w-full h-full">
                        <div className="lg:text-xl">
                            {
                                state.activeChat === 1 ?
                                    <p>
                                        PAZIENTE CON DOLORE AL GINOCCHIO<sup>1</sup>
                                    </p>
                                    : state.activeChat === 2 ?
                                        <p>
                                            PAZIENTE CON DOLORE ALLA CAVIGLIA<sup>1</sup>
                                        </p>
                                        : state.activeChat === 3 ?
                                            <p>
                                                PAZIENTE CON DOLORE ALLA CERVICALE<sup>4-5</sup>
                                            </p>
                                            :
                                            <p>
                                                PAZIENTE CON DOLORE ALLA SCHIENA<sup>1</sup>
                                            </p>
                            }
                        </div>

                        <div className="text-xs lg:text-[0.9rem] lg:leading-[1.35rem]">
                            {state.activeChat === 1 ?
                                <p>
                                    Margherita, 75 anni, entra in farmacia per chiedere un consiglio, lamentando un
                                    dolore al ginocchio*.
                                </p>
                                :
                                state.activeChat === 2 ?
                                    <p>
                                        Giulia, 25 anni, entra in farmacia per chiedere un consiglio, lamentando un
                                        dolore alla caviglia. Riesce a camminare ma con una lieve zoppia*.
                                    </p>
                                    :
                                    state.activeChat === 3 ?
                                        <p>
                                            Ilaria, 40 anni, entra in farmacia per chiedere un consiglio, lamentando un
                                            dolore alla zona cervicale*.
                                        </p>
                                        :
                                        <p>
                                            Mario, 42 anni, entra in farmacia per chiedere un consiglio per il suo mal
                                            di schiena, tenendo una mano sulla zona lombare*.
                                        </p>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Header;
