import Header from "../partials/Header";
import Footer from "../partials/Footer";

const References = () => {
    return (
        <div
            className="relative flex flex-col lg:justify-center bg-chat w-full h-full">
            <Header type="references"/>

            <div className="main-container flex-col text-blue bg-white w-full p-4">
                <div className="overflow-auto">
                    <p className="font-bold text-3xl">
                        Bibliografia
                    </p>

                    <ul className="lg:text-xs mt-5">
                        <li>
                            Gamaleri FC, et al. ADVICE – Automedicazione & Dolore: Valutazioni e Indicazioni da un
                            Comitato di Esperti. INFocus. Anno XXV, N. 2, gennaio 2022.
                        </li>

                        <li>
                            Voltaren emulgel 2%. Riassunto delle Caratteristiche del Prodotto.
                        </li>

                        <li>
                            Voltalgan. Riassunto delle Caratteristiche del Prodotto.
                        </li>

                        <li>
                            Vijiaratnam N, Williams DR, Bertram KL. Neck pain: What if it is not musculoskeletal? Aust J
                            Gen Pract. 2018 May;47(5):279-282.
                        </li>

                        <li>
                            Cohen SP et al. Advances in the diagnosis and management of neck pain. BMJ. 2017 Aug 14 358
                            j322.
                        </li>

                        <li>
                            VoltadvanceGo. Riassunto delle Caratteristiche del Prodotto.
                        </li>

                        <li>
                            Cohen SP. Epidemiology, diagnosis, and treatment of neck pain. Mayo Clin Proc. 2015
                            Feb;90(2):284-99.
                        </li>

                        <li>
                            Joshi S. et al. Thoracic Posture and Mobility in Mechanical Neck Pain Population: A Review
                            of the Literature. Asian Spine J 2019;13(5):849-860.
                        </li>

                        <li>
                            Wong JJ et al. Are non-steroidal anti-inflammatory drugs effective for the management of
                            neck pain and associated disorders, whiplash-associated disorders, or non-specific low back
                            pain? A systematic review of systematic reviews by the Ontario Protocol for Traffic Injury
                            Management (OPTIMa) Collaboration. Eur Spine J. 2016 Jan;25(1):34-61.
                        </li>

                        <li>
                            Voltadol Unidie. Riassunto delle Caratteristiche del Prodotto.
                        </li>
                    </ul>
                </div>
            </div>

            <Footer type="references"/>
        </div>
    );
}

export default References;
