import {useContext, useEffect, useState} from "react";
import {speech} from "../../../utilis/speech";
import {AppContext} from "../../../reducer/contexts";
import {actionTypes} from "../../../reducer/actionTypes";
import ChatBoxPatient from "./ChatBoxPatient";
import ChatBoxDoctor from "./ChatBoxDoctor";
import {chatTimeout} from "../../../utilis/common";
import _ from "lodash";

const ChatBox = (props) => {
    const {state, dispatch} = useContext(AppContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const currentSpeech = speech[state.activeChat - 1][props.subject][props.index];

    useEffect(() => {
        let chatBoxTimeout;
        let prevSpeech;

        for (const speechKey in speech[state.activeChat - 1]) {
            prevSpeech = speech[state.activeChat - 1][speechKey].filter(chat => _.isEqual(chat.next, {
                subject: props.subject,
                index: props.index
            }));

            if (prevSpeech.length) {
                break;
            }
        }

        prevSpeech = prevSpeech[0];

        let charCount = 0;
        if (typeof prevSpeech?.text.props.children === "string") {
            charCount = prevSpeech.text.props.children.length;
        } else {
            prevSpeech?.text.props.children.forEach(text => {
                if (typeof text === "string") {
                    charCount += text.length;
                }
            });
        }

        const timeout = charCount < 150 || currentSpeech.type === "question" ? chatTimeout : chatTimeout + 1000;

        chatBoxTimeout = setTimeout(() => {
            setIsLoaded(true);

            if (currentSpeech.next) {
                const nextSpeech = speech[state.activeChat - 1][currentSpeech.next.subject][currentSpeech.next.index];

                if (nextSpeech?.type === "question") {
                    let index = currentSpeech.next.index;
                    while (speech[state.activeChat - 1].doctor[index].type === "question") {
                        dispatch({
                            type: actionTypes.ADD_MESSAGE_TO_CHAT,
                            payload: {
                                subject: "doctor",
                                index
                            }
                        });

                        if (speech[state.activeChat - 1].doctor[index].questionEnd) {
                            break;
                        }

                        index++;
                    }
                } else if (nextSpeech?.type !== "answer") {
                    dispatch({
                        type: actionTypes.ADD_MESSAGE_TO_CHAT,
                        payload: {
                            subject: currentSpeech.next.subject,
                            index: currentSpeech.next.index
                        }
                    });
                }
            } else {
                dispatch({type: actionTypes.START_STOP_PLAYING});
            }
        }, timeout);

        return () => clearTimeout(chatBoxTimeout);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {props.subject === "patient" &&
                <ChatBoxPatient isLoaded={isLoaded} currentSpeech={currentSpeech} index={props.index}/>}
            {props.subject === "doctor" &&
                <ChatBoxDoctor isLoaded={isLoaded} currentSpeech={currentSpeech} index={props.index}/>}
        </>
    );
}

export default ChatBox;
