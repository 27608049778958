import ChatButton from "../partials/ChatButton";
import frame from "../../assets/images/frame.png";
import mobileFrame from "../../assets/images/frame-mobile.png";
import logo from "../../assets/images/logos/logo.png";
import logoEdra from "../../assets/images/logos/logo-edra.png";
import legalNotes from "../../assets/pdf/legal-notes.pdf";
import privacyPolicy from "../../assets/pdf/privacy-policy.pdf";

const Home = () => {
    return (
        <>
            <div className="relative flex flex-col text-white w-full lg:h-full p-3 max-lg:pb-0">
                <img src={mobileFrame} className="lg:hidden absolute top-0 left-0 w-full h-96" alt="Frame"/>
                <img src={frame} className="hidden lg:block absolute top-0 left-0 w-full h-4/5" alt="Frame"/>

                <div className="z-10 flex max-lg:flex-col flex-grow w-full lg:h-full">
                    <div className="flex flex-col lg:justify-between w-full lg:w-1/2 h-full">
                        <div className="flex flex-col w-full">
                            <img src={logo} className="lg:hidden self-end w-24" alt="Logo"/>

                            <p className="text-lg mt-5 lg:mt-[2%]">
                                VIRTUAL CHAT
                            </p>
                            <p className="text-3xl lg:text-2xl xl:text-3xl font-bold">
                                GESTIRE IL DOLORE<br/>
                                MUSCOLO-SCHELETRICO<br/>
                                IN FARMACIA
                            </p>

                            <div className="bg-blue rounded-br-4xl w-full lg:w-3/5 p-5 lg:p-[3%] mt-5 lg:mt-[3%]">
                                <p className="text-xl lg:text-base xl:text-lg font-bold">
                                    Perché?
                                </p>
                                <p className="lg:text-[.65rem] xl:text-xs">
                                    Per impostare un dialogo corretto con il paziente con dolore, così da arrivare al
                                    consiglio
                                    migliore e costruire un rapporto di fiducia.
                                </p>

                                <p className="text-xl lg:text-base xl:text-lg font-bold mt-5 lg:mt-[4%]">
                                    Come?
                                </p>
                                <p className="lg:text-[.65rem] xl:text-xs">
                                    Grazie a quattro simulazioni di ipotetici dialoghi tra un farmacista e un paziente
                                    con dolore.
                                </p>

                                <hr className="border-t-orange my-5 lg:my-[6%]"/>

                                <p className="lg:text-[.65rem] xl:text-xs text-orange font-semibold italic">
                                    Seleziona un paziente<br/>per avviare il dialogo.
                                </p>
                            </div>
                        </div>

                        <p className="hidden lg:block text-[.4rem] text-black w-11/12">
                            Materiale promozionale destinato ai farmacisti depositato presso l’AIFA il: 24/07/2024. RCP
                            inclusi nel mezzo.<br/>
                            VIETATA LA DISTRIBUZIONE O L’ESPOSIZIONE AL PUBBLICO.<br/>
                            Codice di deposito Aziendale: PM-IT-VOLT-24-00061
                        </p>
                    </div>

                    <div
                        className="max-lg:relative -left-4 max-lg:bg-orange-dark w-[calc(100%+theme(spacing.8))] lg:w-1/2 h-full">
                        <div
                            className="flex flex-col justify-between max-lg:bg-white max-lg:rounded-br-4xl w-full h-full">
                            <img src={logo} className="hidden lg:block self-end w-auto h-[2.5%]" alt="Logo"/>

                            <div
                                className="flex flex-wrap justify-center lg:justify-between items-center w-full h-full max-lg:space-y-8 max-lg:my-8">
                                <ChatButton chat={1}/>
                                <ChatButton chat={2}/>
                                <ChatButton chat={3}/>
                                <ChatButton chat={4}/>
                            </div>

                            <div className="hidden lg:flex justify-end items-end w-full h-[3%]">
                                <a href={privacyPolicy} className="text-black text-[.5rem] cursor-pointer"
                                   target="_blank" rel="noreferrer">
                                    Privacy e cookie policy
                                </a>
                                <a href={legalNotes} className="text-black text-[.5rem] cursor-pointer mx-5"
                                   target="_blank" rel="noreferrer">
                                    Note legali
                                </a>
                                <img src={logoEdra} className="w-auto h-full" alt="Logo Edra"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="lg:hidden flex flex-col items-center text-white text-xs bg-orange-dark text-center w-full py-5">
                <p>
                    Materiale promozionale destinato ai farmacisti depositato presso l’AIFA il: 24/07/2024. RCP inclusi
                    nel mezzo.<br/>
                    VIETATA LA DISTRIBUZIONE O L’ESPOSIZIONE AL PUBBLICO.<br/>
                    Codice di deposito Aziendale: PM-IT-VOLT-24-00061
                </p>

                <div className="flex justify-center w-full my-4 space-x-4">
                    <a href={privacyPolicy} className="cursor-pointer" target="_blank" rel="noreferrer">
                        Privacy e cookie policy
                    </a>
                    <a href={legalNotes} className="cursor-pointer" target="_blank" rel="noreferrer">
                        Note legali
                    </a>
                </div>

                <img src={logoEdra} className="lg:hidden w-20" alt="Logo"/>
            </div>
        </>
    );
}

export default Home;
