import {AppContext} from "../../reducer/contexts";
import {useContext} from "react";
import {actionTypes} from "../../reducer/actionTypes";
import patient1 from "../../assets/images/patient-1.gif";
import patient2 from "../../assets/images/patient-2.gif";
import patient3 from "../../assets/images/patient-3.gif";
import patient4 from "../../assets/images/patient-4.gif";

const ChatButton = ({chat}) => {
    const {dispatch} = useContext(AppContext);

    const handlePlayBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_CHAT,
        payload: chat,
    });

    return (
        <button
            className="flex flex-col items-center font-bold text-blue text-start lg:text-center w-96 lg:w-[40%]"
            onClick={handlePlayBtnClick}
        >
            <div className="w-3/5 lg:w-5/6">
                <img src={chat === 1 ? patient1 : chat === 2 ? patient2 : chat === 3 ? patient3 : patient4}
                     className="rounded-full w-full h-auto"
                     alt={`start chat ${chat}`}/>
            </div>


            <p className="text-lg lg:text-xs mt-2">
                PAZIENTE CON DOLORE {chat === 1 ? "AL" : "ALLA"}
            </p>

            <p className="text-4xl lg:text-2xl">
                {chat === 1 ? "GINOCCHIO" : chat === 2 ? "CAVIGLIA" : chat === 3 ? "CERVICALE" : "SCHIENA"}
            </p>
        </button>
    );
}

export default ChatButton;
